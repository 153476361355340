@media screen and (max-width: 900px) {

    .filterGrid {
        min-height: auto!important;
    }
}

.filterGrid {
    min-height: 100vh;
    padding: 0;
    color: #fff;
}

.courseTile {
    -webkit-box-shadow: 1px 1px 11px 0px rgba(202,43,43,0.48);
    box-shadow: 1px 1px 11px 0px rgba(202,43,43,0.48);
    border: 1px solid #CA2B2B;
}

.filterWappen {
    max-width: 8rem;
}

.courseContainer {
    border: 1px solid gray;
    margin: 1rem;
}
