.status {
    display: inline-flex;
    align-items: center;
}
i.reserved {
    background: rgb(255, 153,0);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: block;
    box-shadow: 0 0 0 0 rgba(255, 128, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

i.reserved-static {
    background: rgb(255, 153,0);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: block;
    box-shadow: 0 0 0 0 rgba(255, 128, 0, 1);
}


i.confirmed {
    background: rgb(0,153,0);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: block;
}

i.rejected {
    background: rgb(204,0,0);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: block;
}
a, a:visited {
    color: inherit;
}

i.closed {
    background: rgb(224,224,224);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: block;
}

.status > span {
    margin-left: 0.7rem;
}


@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 128, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 128, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
