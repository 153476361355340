@media screen and (min-width: 900px) and (max-width: 1040px) {
    img.landinghero {
        max-width: 23rem!important;
        margin: -2rem 0 0 4.5rem!important;
    }

}

@media screen and (max-width: 900px) {

    .MuiGrid-item {
      padding: 1rem;
    }
    .MuiGrid-item h1 {
        font-size: calc(100% + 0.5rem );
    }

    #mobileNavIcon {
        display: block;
    }
    .landingHeroContainer {
        flex-wrap: nowrap !important;
        flex-direction: column-reverse !important;
    }

    .landingNavContainer {
        font-size: calc(100% + 0.5rem );
        color: white;
        line-height: 3rem;
        display: flex;
        flex-direction: column;
        height: 100vh;
        background-color: red;
        width: 100vw;
        z-index: 900;
        position: fixed;
        top: 15rem;
        left: 0;
    }

    #mobileNav {
        display: block;
    }

    img.landinghero {
        width: 85%;
        max-width: 25rem;
        height: auto;
        box-shadow: 2rem 1rem #CA2B2B;
        margin: 2rem;
    }

    img.responsive {
        width: 65%;
        max-width: 25rem;
        height: auto;
        box-shadow: 2rem 1rem #CA2B2B;
        margin: 2rem;
    }

}

@media screen and (min-width: 900px) {
    #mobileNavIcon {
        display: none;
    }

    .landingNavContainer {
        height: 100%;
        padding: 3rem 1rem 1rem 1rem;
        background: #CA2B2B;
        color: #fff;
        margin: 0 0 10rem 0;
        display: block;
    }

    .landingNavContainer > a {
        color: #fff;
        text-decoration: none;
        padding: 0 1rem 0 1rem;
        background-color: #CA2B2B;
    }

    img.landinghero {
        max-width: 28rem;
        box-shadow: 5rem 2rem #CA2B2B;
        margin: -2rem 0 0 4.5rem;
        padding: 0!important;
    }


    img.shadow {
        box-shadow: -5rem 4rem #CA2B2B;
    }
}
